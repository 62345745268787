<template>
  <div v-if="canAccess('cobranzas_monitor')">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-lg-2">
        <div class="card summary">
          <span class="title">Anticipadas</span>
          <span class="detail">Cuentas Anticipadas</span>
          <span class="count visitors" style="font-size: 20px;">{{ account_anticiped }}</span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary">
          <span class="title">1er. Cuota</span>
          <span class="detail">Cuentas Primera cuota</span>
          <span class="count purchases" style="font-size: 20px;">{{ first_fee }}</span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary">
          <span class="title">Obligatorias</span>
          <span class="detail">Cuentas Obligatorias</span>
          <span class="count revenue" style="font-size: 20px;">{{ account_required }}</span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary">
          <span class="title">Semanal</span>
          <span class="detail">Asignaciones en la semana</span>
          <span class="count visitors" style="font-size: 20px;">{{ assignments_week }}</span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary">
          <span class="title">Mensual</span>
          <span class="detail">Asignaciones en el mes</span>
          <span class="count purchases" style="font-size: 20px;">{{ assignments_month }}</span>
        </div>
      </div>
      <div class="p-col-12 p-lg-2">
        <div class="card summary">
          <span class="title">Total</span>
          <span class="detail">Acumulado de asignaciones</span>
          <span class="count revenue" style="font-size: 20px;">{{ assignments }}</span>
        </div>
      </div>
    </div>
    <div class="p-grid p-fluid dashboard">
      <div class="p-fluid p-mt-1 p-mr-2">
                                <span class="p-float-label">
                                    <InputText id="enroll" v-model="search" @keyup.enter="filter()"/>
                                    <label for="enroll">Buscar por</label>
                                </span>
      </div>
      <div class="p-fluid p-mt-1  p-mr-2">
                    <span class="p-float-label df">
                        <Calendar id="dateInit" :showIcon="true" v-model="dateInit" dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2020:2050" :maxDate="dateFinish" @date-select="filter"/>
                        <label for="dateInit">Fecha Desde</label>
                    </span>
      </div>
      <div class="p-fluid p-mt-1  p-mr-2">
                    <span class="p-float-label df">
                        <Calendar id="dateFinish" :showIcon="true" v-model="dateFinish" dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2020:2050" :maxDate="dateFinishMax" @date-select="filter"
                                  :minDate="dateInit"/>
                        <label for="dateFinish">Fecha Hasta</label>
                    </span>
      </div>
      <div class="p-fluid p-mt-1  p-mr-2" v-if="isAdmin">
                                <span class="p-float-label df">
                                    <Dropdown id="country" v-model="country" :options="countries" optionLabel="country"
                                              @change="filter();subsidiary=null"/>
                                    <label for="country">País</label>
                                </span>
      </div>
      <div class="p-fluid  p-mt-1 p-mr-2" v-if="canAccess('cobranzas_admin')">
                                <span class="p-float-label df">
                                    <Dropdown id="subsidiary" v-model="subsidiary" :options="subsidiaries" optionLabel="name"
                                              @change="filter"/>
                                    <label for="subsidiary">Filial</label>
                                </span>
      </div>
      <div class="p-fluid  p-mt-1 p-mr-2" v-if="canAccess('cobranzas_admin')">
                                <span class="p-float-label df">
                                    <Dropdown id="executive_account"
                                              v-model="executive_account"
                                              :options="executives"
                                              optionLabel="executive"
                                              :show-clear="true"
                                              :filter="true"
                                              @change="filter"/>
                                    <label for="executive_account">Ejecutivo</label>
                                </span>
      </div>
      <div class="p-fluid p-mt-1  p-mr-2">
        <Button label="Limpiar" icon="pi pi-undo" class="p-button-secondary p-mr-2"
                @click="reload"/>
      </div>
      <div class="p-fluid p-mt-1  p-mr-2">
        <Button label="Exportar" class="p-button-info p-mr-2"
                @click="exportReport('XLS')"/>
      </div>
      <div class="p-fluid p-mt-1  p-mr-2">
        <Button label="Imprimir" class="p-button-info p-mr-2"
                @click="exportReport('PDF')"/>
      </div>
    </div>
    <div class="p-grid p-fluid dashboard" style="padding: 10px;background: white">
      <div class="card card-w-title" id="divCard" style="padding:0px;min-width: 350px;width: 100%; overflow-x: auto;">
          <DataTable :value="viewData" :paginator="true" dataKey="id"
                     class="p-datatable-sm p-datatable-striped"
                     responsiveLayout="scroll"
                     v-model:selection="currentItem"
                     paginatorPosition="bottom"
                     :lazy="true"
                     :totalRecords="page.total"
                     :rows="page.per_page"
                     :loading="loading"
                     @page="onPage($event)"
          >
            <Column header="" headerStyle="width: 3rem">
              <template #body="slotProps">
                <Button @click="onRowExpand(slotProps)" style="cursor: pointer" icon="pi pi-eye" ></Button>
              </template>

            </Column>
            <Column headerStyle="text-align: center;width:180px" >
              <template #header="">
                <div>Ejecutivo<br/>Cuenta</div>
              </template>
              <template #body="slotProps">
                <div class="center">{{ slotProps.data?.executive }}
                </div>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:80px">
              <template #header="">
                <div>Cuentas<br/>Asignadas</div>
              </template>
              <template #body="slotProps">
                <div class="center"> {{ slotProps.data.accounts_assigments }}</div>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:100px">
              <template #header="">
                <table>
                  <tr>
                    <td colspan="2">General</td>
                  </tr>
                  <tr>
                    <td>COB</td>
                    <td>CTA %</td>
                  </tr>
                </table>
              </template>
              <template #body="slotProps">
                <table>
                  <tr>
                    <td>{{ slotProps.data.general_cob }}</td>
                    <td>{{ slotProps.data.general_cta }}%</td>
                  </tr>
                </table>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:180px">
              <template #header="">
                <table>
                  <tr>
                    <td colspan="3">Cobranza Presencial</td>
                  </tr>
                  <tr>
                    <td colspan="2">Cuentas</td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>ASIG.</td>
                    <td>COB</td>
                    <td>%</td>
                  </tr>
                </table>
              </template>
              <template #body="slotProps">
                <table>
                  <tr>
                    <td>{{ slotProps.data.presencial_assigments }}</td>
                    <td>{{ slotProps.data.presencial_cob }}</td>
                    <td>{{ slotProps.data.presencial_cta }}%</td>
                  </tr>
                </table>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:180px">
              <template #header="">
                <table>
                  <tr>
                    <td colspan="3">Cobranza Online</td>
                  </tr>
                  <tr>
                    <td colspan="2">Cuentas</td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>ASIG.</td>
                    <td>COB</td>
                    <td>%</td>
                  </tr>
                </table>
              </template>
              <template #body="slotProps">
                <table>
                  <tr>
                    <td>{{ slotProps.data.online_assigments }}</td>
                    <td>{{ slotProps.data.online_cob }}</td>
                    <td>{{ slotProps.data.online_cta }}%</td>
                  </tr>
                </table>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:80px">
              <template #header="">
                <div class="p-text-center">
                  Valor <br/>a cobrar
                </div>
              </template>
              <template #body="slotProps">
                <div>{{ slotProps.data.value_cob }}</div>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:120px">
              <template #header="">
                <div class="p-text-center">
                  Valor <br/>a cobrar<br/>mes anterior
                </div>
              </template>
              <template #body="slotProps">
                <div>{{ slotProps.data.value_ant_mont }}</div>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:80px">
              <template #header="">
                <div class="p-text-center">
                  Neto <br/>a cobrar
                </div>
              </template>
              <template #body="slotProps">
                <div>{{ slotProps.data.neto_cob }}</div>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:80px">
              <template #header="">
                <div class="p-text-center">
                  V. Cobrar<br/>normal
                </div>
              </template>
              <template #body="slotProps">
                <div>{{ slotProps.data.value_cob_normal }}</div>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:80px">
              <template #header="">
                <div class="p-text-center">
                  Primera<br/>cuota
                </div>
              </template>
              <template #body="slotProps">
                <div>{{ slotProps.data.first_fee_assigments }}</div>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:80px">
              <template #header="">
                <div class="p-text-center">
                  Cuota<br/>cobrado
                </div>
              </template>
              <template #body="slotProps">
                <div>{{ slotProps.data.first_fees_cob }}</div>
              </template>
            </Column>
            <Column headerStyle="text-align: center;width:180px">
              <template #header="">
               <table>
                 <tr>
                   <td>%</td>
                   <td>PF</td>
                   <td>PJ</td>
                   <td>R.B.</td>
                 </tr>
               </table>
              </template>
              <template #body="slotProps">
                <table>
                  <tr>
                    <td>{{ slotProps.data.percentage_first_fee }}</td>
                    <td>{{ slotProps.data.payment_finish }}</td>
                    <td>{{ slotProps.data.payment_pj }}</td>
                    <td>{{ slotProps.data.payment_rb }}</td>
                  </tr>
                </table>
              </template>
            </Column>
          </DataTable>
        </div>
      <Dialog  :modal="true" :header="'Lista de Matrículas: '+selectedExecutive" v-model:visible="display" :breakpoints="{'960px': '85vw', '640px': '100vw'}" :style="{width: '75vw'}">
        <div class="orders-subtable">
                 <span class="p-input-icon-left p-ml-3" style="width: 200px">
                    <i class="pi pi-search"/>
                    <InputText v-model="filtersMat['global']" placeholder="Buscar"/>
                 </span>
        </div>
        <div class="orders-subtable" style="overflow-y: auto;height: 520px;margin-top: 10px">
          <DataTable :value="enrollments_details" responsiveLayout="scroll" row-hover="false" :filters="filtersMat">

            <Column field="enrollments" header="Matrícula" sortable headerStyle="text-align: center;">
              <template #body="slotSubProps">
                <div class="center">{{ slotSubProps.data.enrollments }}</div>
              </template>
            </Column>
            <Column field="registration" header="Fecha Registro" sortable headerStyle="text-align: center;"></Column>
            <Column header="Total" headerStyle="text-align: center;">
              <template #body="slotSubProps">
                <div class="center">{{ formatMoney(slotSubProps.data.total) }}</div>
              </template>
            </Column>
            <Column header="Valor pagado" headerStyle="text-align: center;">
              <template #body="slotSubProps">
                <div class="center">{{ formatMoney(slotSubProps.data.paymented) }}</div>
              </template>
            </Column>
            <Column header="Porcentaje" headerStyle="text-align: center;">
              <template #body="slotSubProps">
                <ProgressBar :value="((slotSubProps.data.paymented*100)/(slotSubProps.data.total==0?1:slotSubProps.data.total)).toFixed(2)" />
              </template>
            </Column>
          </DataTable>
        </div>
      </Dialog>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";
import service from "../../service/raiseMoney.service";
import moment from "moment";
import mw from "../../service/mw.service";
import serviceReport from "../../../reports/service/report.service";
import FileSaver from 'file-saver';
export default {
  mixins: [bouncer],
  name: "RaiseMoneyList",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  components: {

  },
  data() {
    return {
      serviceReport: serviceReport('month-close-payments'),
      filtersMat:{},
      executiveSearch:null,
      page: {
        page: 1,
        per_page:20,
        total: 0,
        order: "desc"
      },
      assignments: 0,
      assignments_month: 0,
      assignments_week: 0,
      account_anticiped:0,
      account_required:0,
      first_fee:0,
      search: '',
      dateFinish: '',
      dateFinishMax: '',
      dateInit: '',
      viewData: [],
      loading: false,
      currentItem: null,
      params: {},
      country: null,
      subsidiary: null,
      executive_account:null,
      countries: [],
      expandedRows: [],
      enrollments_details:[],
      display:false,
      selectedExecutive:''
    }
  },
  methods: {

    onRowExpand(event) {
      this.executiveSearch=event.data.executive_id
      this.$toast.add({severity: 'info', summary: 'Ejecutivo de Cuenta', detail:  event.data.executive, life: 3000});
      const config = {
        date_init: this.dateInit ? moment(this.dateInit).format("YYYY-MM-DD") : undefined,
        date_finish: this.dateFinish ? moment(this.dateFinish).format("YYYY-MM-DD") : undefined,
        country: this.country?.id,
        subsidiary: this.subsidiary?.id,
        executive: this.executiveSearch,
      };
      this.selectedExecutive=event.data.executive
      service.getMonitorEnrollmentsDetail(this.executiveSearch, config).then(x => {
        this.enrollments_details = x.data.result;
       this.display= true
      }).finally(() => this.loading = false);



    },
    b64EncodeUnicode(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
    },
    reload(){
      this.search = '';
      const date = new Date();
      this.dateFinish =  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
      this.dateFinishMax=  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
      this.dateInit = new Date(date.getFullYear(), 0, 1);
      this.country = null;
      this.subsidiary = null;
      this.executive_account = null;
      this.filter();
    },
    onPage(event) {
      this.page.page = event.page;
      this.page.page++;
      this.loadEnrollments()
    },
    filter() {
      this.loading = true;
      this.page.page = 1
      this.loadEnrollments()
    },
    formatMoney(val) {
      return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })).format(val);
    },
    sumRecaudations(payments) {
      return  payments
          .map(item => item.value_paid)
          .reduce((prev, curr) => prev + curr, 0);
    },
    loadEnrollments() {
      this.viewData = [];
      this.loading = true;
      const config = {
        ...this.page,
        ...{q: this.search},
        date_init: this.dateInit ? moment(this.dateInit).format("YYYY-MM-DD") : undefined,
        date_finish: this.dateFinish ? moment(this.dateFinish).format("YYYY-MM-DD") : undefined,
        country: this.country?.id,
        subsidiary: this.subsidiary?.id,
        executive: this.executive_account?.id,
      };
      service.getMonitorEnrollments( config).then(x => {
        this.viewData = x.data.result.data;
        this.page.total = x.data.result.total;
        this.assignments = x.data.assignments;
        this.assignments_month = x.data.assignments_month;
        this.assignments_week = x.data.assignments_week;
        this.account_anticiped = x.data.account_anticiped;
        this.account_required = x.data.account_required;
        this.first_fee = x.data.first_fee;
      }).finally(() => this.loading = false);
      mw.getMWSELiq().then(x => {
        this.countries = x.data
        if (!this.isAdmin) {
          this.country = this.countries[0];
        }
      })
    },
    getTypePayment(type_payment) {
      if(type_payment === 'INSCRIPTION') {
        return 'Inscripción';
      } else if (type_payment === 'FEE') {
        return 'Cuota';
      } else {
        return type_payment;
      }
    },

    exportReport(command) {
      if (this.country == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
        return;
      }
      this.loading = true;
      this.serviceReport.getMonthClose({
        date_start: this.dateInit ? moment(this.dateInit).format("YYYY-MM-DD") : undefined,
        date_end: this.dateFinish ? moment(this.dateFinish).format("YYYY-MM-DD") : undefined,
        country: this.country?.id,
        export: command
      }).then(x => {
            if (command === 'PDF') {
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});
              this.loading = false;
              FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            } else {
              this.loading = false;
              window.open(x.data.url_file)
            }
          }).catch((err) => {
        this.loading = false;
        let error = this.$utils.formatError(err.response.data);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })

    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    subsidiaries() {
      return this.country ? this.country.subsidiaries : [];
    },
    executives() {
      return this.country ? this.country.executives : [];
    }
  },
  mounted() {
    if (this.canAccess('cobranzas_monitor')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Monitor de Matrículas', url: "javascript:void(0);"},
      ]);
    }

    const date = new Date();
    this.dateFinish =  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
    this.dateFinishMax=  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
    this.dateInit = new Date(date.getFullYear(), 0, 1);
    this.loadEnrollments();

    document.getElementById('divCard').addEventListener(
        'scroll',
        function()
        {
          const scroll = document.getElementById("divCard").scrollLeft;
          if (scroll === 0) {
            document.getElementsByClassName("p-paginator")[0].style.cssText="margin-left:0px;width:100%";
          } else {
            document.getElementsByClassName("p-paginator")[0].style.cssText="margin-left:"+(document.getElementById("divCard").scrollLeft) +"px;width:100%";
          }
        },
        false
    );
  }
}
</script>

<style>

.p-fieldset {
  margin-top: 15px;
}

.p-field {
  padding-top: 15px;
}

.p-dialog-footer {
  padding: 0;
  margin-bottom: -15px;
}

.p-grid {
  margin-top: 15px;
}

.p-toolbar {
  background: none;
  border: none;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  height: 30px !important;
}

.df, .df #status, .p-fluid .p-dropdown {
  height: 100%;
}

.center, .p-dialog .p-dialog-footer {
  text-align: center;
}

.p-message-wrapper > span {
  display: none !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  text-align: center!important;
}

</style>
